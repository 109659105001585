<template>
  <main>
    <v-card flat>
      <v-card-title class="title-block">
        <div>
          Offers List

          <v-btn icon class="ml-3" @click="getOffers"  :disabled="!isAuthenticated || offers.loading"><v-icon>{{ icons.mdiReload }}</v-icon></v-btn>
        </div>

        <v-btn
          color="primary"
          :loading="loading"
          @click="createOffer"
          :disabled="!isAuthenticated || loading"
        >Create Offer</v-btn>
      </v-card-title>

      <v-card-text>
        <v-data-table
          :headers="tableColumns"
          :footer-props="{
            'items-per-page-options': [10, 10],
            'disable-items-per-page': true,
            'disable-pagination': offers.loading
          }"
          :items="offers.list"
          :options.sync="offers.options"
          :page="offers.meta.page"
          :server-items-length="offers.meta.total || 0"
          :loading="offers.loading"
          @pagination="changePagination($event)"
          :no-data-text="'No data available'"
          :loading-text="'Loading, pls wait'"
          class="text-no-wrap"
        >
          <template #[`item.createdAt`]="{item}">
            {{ formatDate(item.createdAt) }}
          </template>

          <template #[`item.budget`]="{item}">
            <!-- <v-progress-linear
              :value="getBudgetPercent(item)"
              rounded
              color="primary"
              height="25"
            >
              <template v-slot:default>
                <strong class="budget-info">{{item.usedBudget}}/{{item.maxBudget}}</strong>
              </template>
            </v-progress-linear> -->
            {{getOfferBudget(item)}}
          </template>

          <template #[`item.closed`]="{item}">
            {{ item.web3data && item.web3data.closed ? 'Yes' : 'No' }}
          </template>

          <template #[`item.balance`]="{item}">
            {{ item.web3data.balance }} {{ item.token }}
          </template>

          <template #[`item.payout`]="{item}">
            {{ item.payout }} {{ item.token }}
          </template>

          <template #[`item.actions`]="{item}">
            <v-menu
              bottom
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn small icon v-bind="attrs" v-on="on">
                  <v-icon size="18">
                    {{ icons.mdiDotsVertical }}
                  </v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item link dense @click="editOffer(item)">
                  <v-list-item-title>
                    <v-icon size="18" class="me-2">{{ icons.mdiFileEdit }}</v-icon>
                    <span>Edit Offer</span>
                  </v-list-item-title>
                </v-list-item>

                <v-list-item link dense @click="checkOfferInstallation(item)">
                  <v-list-item-title>
                    <v-icon size="18" class="me-2">{{ icons.mdiCogOutline }}</v-icon>
                    <span>Installation</span>
                  </v-list-item-title>
                </v-list-item>

                <v-list-item link dense @click="transferOffer(item)" v-if="canTransferOffer(item)">
                  <v-list-item-title>
                    <v-icon size="18" class="me-2">{{ icons.mdiSwapHorizontal }}</v-icon>
                    <span>Transfer</span>
                  </v-list-item-title>
                </v-list-item>

                <v-list-item link dense @click="addOfferBudget(item)">
                  <v-list-item-title>
                    <v-icon size="18" class="me-2">{{ icons.mdiCurrencyUsd }}</v-icon>
                    <span>Budget</span>
                  </v-list-item-title>
                </v-list-item>

                <v-list-item link dense v-if="canRemoveOffer(item)" @click="removeOffer(item)">
                  <v-list-item-title>
                    <v-icon size="18" class="me-2" color="error">{{ icons.mdiDeleteOutline }}</v-icon>
                    <span class="error--text">Remove</span>
                  </v-list-item-title>
                </v-list-item>

                <v-list-item link dense v-if="canCloseOffer(item)" @click="closeOffer(item)">
                  <v-list-item-title>
                    <v-icon size="18" class="me-2" color="error">{{ icons.mdiCloseNetworkOutline }}</v-icon>
                    <span class="error--text">Close</span>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </main>
</template>

<script>
import {
  mdiInformation,
  mdiReload,
  mdiFileEdit,
  mdiShieldLock,
  mdiDotsVertical,
  mdiCogOutline,
  mdiCurrencyUsd,
  mdiDeleteOutline,
  mdiSwapHorizontal,
  mdiCloseNetworkOutline,
} from '@mdi/js'
import { mapGetters, mapState } from 'vuex'
import moment from 'moment'

import { getGasPriceForNetwork } from '@/utils/web3'
import { eventBus } from '@/utils/eventBus'

export default {
  data: () => ({
    icons: {
      mdiInformation,
      mdiReload,
      mdiFileEdit,
      mdiShieldLock,
      mdiSwapHorizontal,
      mdiCogOutline,
      mdiDotsVertical,
      mdiCurrencyUsd,
      mdiDeleteOutline,
      mdiCloseNetworkOutline,
    },
    loading: false,
    offers: {
      loading: false,
      docs: [],
      meta: {},
      options: {
        sortBy: ['id'],
        sortDesc: [true],
        itemsPerPage: 10,
      },
    },
  }),
  computed: {
    ...mapGetters('web3auth', ['isAuthenticated', 'userdata']),
    ...mapState({
      web3: state => state.web3auth.web3,
      web3config: state => state.app.web3,
    }),
    tableColumns() {
      return [
        { text: 'NAME', value: 'name', sortable: false },
        { text: 'TYPE', value: 'type', sortable: false },
        { text: 'PAYOUT', value: 'payout', sortable: false },
        { text: 'CHAIN', value: 'blockchain', sortable: false },
        { text: 'BUDGET', value: 'budget', sortable: false },
        { text: 'BALANCE', value: 'balance', sortable: false },
        { text: 'STATUS', value: 'status', sortable: false },
        { text: 'CLOSED', value: 'closed', sortable: false },
        { text: 'ACTIONS', value: 'actions', sortable: false },
      ]
    },
  },
  created() {
    eventBus.$on('offer-builder-update', () => {
      this.getOffers({ page: 1 })
    })

    eventBus.$on('offer-transfer-update', () => {
      this.getOffers({ page: 1 })
    })

    eventBus.$on('offer-budget-update', () => {
      this.getOffers({ page: 1 })
    })
  },
  methods: {
    getOffers(query) {
      if (!this.isAuthenticated) return

      this.offers.loading = true

      const params = {
        limit: this.offers.options.itemsPerPage,
        query: JSON.stringify(query),
        sort: { createdAt: 'desc' },
        ...params,
      }

      this.$http
        .get('/offer/user', { params })
        .then(({ data }) => {
          const { meta, docs } = data

          this.offers.meta = meta
          this.offers.list = docs
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.$nextTick(() => {
            this.offers.loading = false
          })
        })
    },

    changePagination(pagination) {
      this.getOffers({
        page: pagination.page,
      })
    },

    getOfferBudget(item) {
      if (item.status !== 'onchain') {
        return 'Not Specified'
      }

      const budget = item.web3data?.budget

      return budget ? `${budget} ${item.token}` : `-`
    },

    showBuilderModal(data) {
      this.$store.commit('modal/SET_OPTIONS', {
        show: true,
        type: 'offer-builder',
        modalAttrs: {
          persistent: true,
          'max-width': 1200,
        },
        componentAttrs: {
          offerData: data,
        },
      })
    },

    createOffer() {
      this.showBuilderModal(null)
    },

    editOffer(item) {
      this.showBuilderModal(JSON.parse(JSON.stringify(item)))
    },

    addOfferBudget(item) {
      this.$store.commit('modal/SET_OPTIONS', {
        show: true,
        type: 'offer-budget',
        modalAttrs: {
          persistent: true,
          'max-width': 500,
        },
        componentAttrs: {
          offerData: JSON.parse(JSON.stringify(item)),
        },
      })
    },

    checkOfferInstallation(item) {
      this.$store.commit('modal/SET_OPTIONS', {
        show: true,
        type: 'offer-installation',
        modalAttrs: {
          persistent: true,
          'max-width': 800,
        },
        componentAttrs: {
          offerData: JSON.parse(JSON.stringify(item)),
        },
      })
    },

    transferOffer(item) {
      this.$store.commit('modal/SET_OPTIONS', {
        show: true,
        type: 'offer-transfer',
        modalAttrs: {
          persistent: true,
          'max-width': 800,
        },
        componentAttrs: {
          offerData: JSON.parse(JSON.stringify(item)),
        },
      })
    },

    getBudgetPercent(item) {
      return ((item.usedBudget * 100) / item.maxBudget).toFixed(2)
    },

    formatDate(date) {
      return moment(date).format('MMMM Do YYYY, h:mm:ss a')
    },

    canRemoveOffer(item) {
      return (
        (item.status === 'draft' || (item.status === 'onchain' && item.web3data?.closed)) && item.deletedAt === null
      )
    },

    canCloseOffer(item) {
      return item.status === 'onchain' && !item.web3data?.closed
    },

    canTransferOffer(item) {
      return item.status === 'draft' && this.userdata.isAdmin
    },

    removeOffer(item) {
      if (this.loading) return

      this.loading = true

      this.$http
        .delete(`/offer/${item._id}`)
        .then(({ data }) => {
          if (data.success) {
            this.$store.dispatch(
              'notification/GENERATE_NOTIFICATION',
              {
                type: 'success',
                message: `Offer has been successfully deleted!`,
              },
              { root: true },
            )

            this.getOffers({ page: 1 })
          }
        })
        .catch(err => {
          if (err.response.data) {
            this.$store.dispatch(
              'notification/GENERATE_NOTIFICATION',
              {
                type: 'error',
                message: err.response.data.message || 'Unknown error!',
              },
              { root: true },
            )
          }
        })
        .finally(() => {
          this.loading = false
        })
    },

    showActionErrorMessage(message = null) {
      this.$store.dispatch(
        'notification/GENERATE_NOTIFICATION',
        {
          type: 'error',
          message: 'Unable to perform this operation! ' + message,
        },
        { root: true },
      )

      this.loading = false
    },

    async closeOffer(item) {
      if (this.loading) return

      this.loading = true

      const { networks, abi } = this.web3config
      const provider = networks[item.blockchain] && networks[item.blockchain].provider

      if (!provider) {
        return this.showActionErrorMessage('[Close Offer]')
      }

      const validNetwork = await this.$store.dispatch('web3auth/SWITCH_NETWORK', networks[item.blockchain].chainId)

      if (!validNetwork) {
        return (this.loading = false)
      }

      const contractAbi = abi['Affiliate']
      const contractAddress = networks[item.blockchain].contracts['Affiliate']

      if (!contractAbi || !contractAddress) {
        return this.showActionErrorMessage('[Close Offer]')
      }

      const contract = new this.web3.eth.Contract(contractAbi, contractAddress)
      const gasPrice = await getGasPriceForNetwork(item.blockchain)

      const receipt = await contract.methods
        .closeOffer(item._id, this.userdata.wallet)
        .send({
          from: this.userdata.wallet,
          gasPrice,
        })
        .then(res => {
          this.$store.dispatch(
            'notification/GENERATE_NOTIFICATION',
            {
              type: 'success',
              message: `The offer budget has been successfully increased!`,
            },
            { root: true },
          )

          return res
        })
        .catch(err => {
          this.$store.dispatch(
            'notification/GENERATE_NOTIFICATION',
            {
              type: 'error',
              message: `There was an error when performing a transaction. [CreateOffer Function]`,
            },
            { root: true },
          )
        })

      if (receipt) {
        await this.$store.dispatch('offer/SAVE_OFFER_CACHE', {
          offerId: item._id,
        })

        this.getOffers({ page: 1 })
      }

      this.loading = false
    },
  },
}
</script>

<style lang="scss" scoped>
.title-block {
  display: flex;
  justify-content: space-between;
}

.budget-info {
  font-size: 10px;
  color: #fff;
}

@media screen and (max-width: 600px) {
  .title-block {
    flex-direction: column;
    align-items: center;
  }
}
</style>
